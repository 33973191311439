import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="LOUD HUMANS"
      heroSubTitle="see, lioning EP out now"
      heroBackgroundImage="/images/loud-humams-golden.jpg"
    >
      <SEO
        title="Loud Humans"
        ogDescription="Atlanta power-trio consisting of Jimmy Ether, Dain Johnson, and Kip Thomas. It's like Joni Mitchell and Nick Drake plugged into a wall of sputtering amplifiers on the verge of collapsing into an abyss of degenerate humanity."
        image="https://loudhumans.us/images/loud-humams-golden.jpg"
        url="https://loudhumans.us"
      />
      <TextBlock
        textPadded
        textLeft={
          <div id="about">
            <p>
              Atlanta power-trio. It's like Joni Mitchell and Nick Drake plugged into a wall of sputtering amplifiers on the verge of collapsing into an abyss of degenerate humanity. Jimmy Ether, Dain Johnson, and Kip Thomas may have been implicated in these activities. Recommended if you like holding your breath underwater competitively.  
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/loud-humams-mauve.jpg" alt="Loud Humans" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div id="music">
            <img src="/images/loud-humans-see-lioning.jpg" alt="see, lioning EP by Loud Humans" />
          </div>
        }
        textRight={
          <div>
            <h2>"see, lioning" EP out now</h2>
            <p>
              The debut EP from Loud Humans with 5 original tracks plus a cover of possibly the greatest song of all time, "Wichita Lineman".
            </p>
            <h3>Download</h3>
            <p>
              Download in lossless or lossy format of your choice at <a href="https://loudhumans.bandcamp.com/" target="_blank">Bandcamp</a>.
            </p>
            <h3>Stream</h3>
            <p>
              Pelt us with fractions of pennies by streaming over your music preview platform of choice:<br />
              <a href="https://open.spotify.com/album/1zpcjhpfq9AvQ31pYO2Ozn" target="_blank">Spotify</a><br />
              <a href="https://music.apple.com/us/album/see-lioning-ep/1643137378?uo=4" target="_blank">Apple Music</a><br />
              <a href="https://tidal.com/browse/artist/34193007" target="_blank">Tidal</a><br />
              <a href="https://amazon.com/music/player/albums/B0BCX14RLV?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_XOmNcQnOCpUkPyAj8P4W7mjtF" target="_blank">Amazon Music</a><br />
              <a href="https://www.deezer.com/us/album/352861047" target="_blank">Deezer</a><br />
              <a href="https://www.youtube.com/channel/UCywMr39ACVc6e6hmp-U4drg" target="_blank">YouTube Music</a><br />
              <a href="https://www.qobuz.com/us-en/album/see-lioning-loud-humans/lyu2szchgo83b" target="_blank">Qobuz</a>
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Keep tabs on us and catch us live.</h2>
            <p>
              The best way to keep tabs on what's going on with the band or our live shows is to follow us on <a href="https://www.facebook.com/LoUdHuMaNsFTW" target="_blank">Facebook</a> or <a href="https://www.instagram.com/loudhumansftw/" target="_blank">Instagram</a>.
            </p>
          </div>
        }
        textRight={
          <div id="socials">
            <img src="/images/loud-humans-live.jpg" alt="Loud Humans Live" />
            <p className="small">Photo by Mike White of <a href="https://www.deadlydesigns.com/" target="_blank">Deadly Designs</a>.</p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Wanna book us or do something interesting? Maybe get a snowcone or italian ice?</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
